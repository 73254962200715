const TemplateCategoriesEnum = {
  ATTENDANCE: 1,
  ADD_NEW_TASK: 2,
  DOCUMENT_APPROVAL: 3,
  CONFIRM_SEATS: 4,
  REASON_ABSENCE: 5,
  DOCUMENT_WAITING_APPROVAL: 6,
  DOCUMENT_REJECTED: 7,
  UPDATE_STATUS_TASK: 8,
  CONFIRM_CONCLUSION: 9,
  UPDATE_USER_VIEW_DOCUMENT: 10,
  UPDATE_USER_UPLOAD_DOCUMENT: 11,
  OPINION: 12,
  USER_SEND_STATED: 13,
  CONFIRM_STATED: 14,
  EXCHANGE_DOCUMENT: 16,
  REJECT_STATED: 17,
  EXCHANGE_DOCUMENT_APPROVAL: 18,
  EXCHANGE_DOCUMENT_REJECT: 19,
  UPDATE_NUMBER_USER_JOIN_AND_LEAVE_MEETING: 20,
  CRITICIZE_SENTIMENT: 21,
  ADD_USER_IN_MEETING: 22
}
export default TemplateCategoriesEnum