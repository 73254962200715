export default {
  'common': {
    'appTitle': 'Paperless Conference',
    'close': 'Close',
    'somethingWentWrong': 'Something went wrong!',
    'networkError': 'Network Connection Error',
    'accept': 'Accept',
    'confirm': 'Confirm',
    'today': 'Today',
    'send': 'Send',
    'success': 'Success',
    'delete': 'Delete',
    'save': 'Save',
    'leaveMeeting': 'Do you want to leave meeting',
    'logOutMeeting': 'Do you want to log out meeting',
    'inputFieldNull': 'Please input/choose',
    'exportExcel': 'Export excel',
    'importExcel': 'Import excel',
    'eventHasEnded': 'The event has ended',
    'approvedWaiting': 'You have registered, please wait for approval',
    'nonumber': 'No',
    'action': 'Action',
    'addNew': 'Add new',
    "not_found_email_info": "Not found email info",
    'search': 'Search',
    'notificationGoToAdminPage': 'Do you want to go to admin page',
    'update': 'Update'
  },
  'login': {
    'title': 'Login',
    'username': 'Username',
    'password': 'Password',
    'loginInvalid': 'Username or password is incorrect',
    'logOut': 'Logout',
    'emailAlready': 'Registered email please enter your password',
    'enterPassword': 'Enter password',
    'clickOnLogin': 'Click on login as if you agreed to the',
    'termsOfUse': 'Terms of Use',
    'forgotPassword': 'Forgot password?',
    'clickHere': 'Click here',
    'toGetBack': 'to get back'
  },
  'register': {
    'title': 'Register',
    'emailIsNotRegistered': 'Email not registered in the system, please enter information to register',
    'setAccessPassword': 'Set an access password',
    'password': 'Password',
    'fullName': 'Full name',
    'firstName': 'First name',
    'phone': 'Phone',
    'position': 'Company position',
    'companyName': 'Company name',
    "password_reset_done_title": "Password reset sent",
    "password_reset_done_c1": "We've emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly.",
    "password_reset_done_c2": "If you don't receive an email, please make sure you've entered the address you registered with, and check your spam folder.",
    "password_reset_set_pass_title": "Enter new password",
    "password_reset_set_pass_content": "Please enter your new password twice so we can verify you typed it in correctly.",
    "password_reset_set_pass_btn1": "New password",
    "password_reset_set_pass_btn2": "Confirm password",
    "password_reset_set_pass_btn3": "Change my password",
    "uppercase": "Uppercase",
    "lowercase": "Lowercase",
    "digit": "Number",
    'checkNewPasswordAndOldPassword': 'Your input password does not match.',
    'validatePasswordError': 'Please enter the correct password format',
    'callBackLogin': 'Go back login',
    "emailTitleC1": "The email activated",
    "emailTitleC2": "Your account was successfully activated!",
    "emailTitleC3": "Please check you email to retrive your password and login",
    "emailTitleC4": "The email is not activate",
    "emailTitleC5": "Your account activate fail!",
  },
  'checkLogin': {
    'loginMessage': 'Enter email, account or phone number to login',
    'loginPlaceholder': 'email, account or phone number',
    'continue': 'Continue',
    'accountNoteExist': 'Account is not exist',
    'phoneNumberDuplicate': 'The number is duplicated in system',
    'notExistEmailInSystem': 'Email does not exist in the system, you want to sign',
    'plsEnterAccount': 'Please enter account innfo email to login',
    'meetingRoomsWithoutPaper': 'Meeting rooms without paper',
    'ICTMeetingRoomsWithoutPaperC1': "Dong Nai province",
    'ICTMeetingRoomsWithoutPaperC2': "System for sending and receiving documents"
  },
  'checkForm': {
    'invalidAccount': 'Login account is required',
    'invalidEmail': 'A valid email is required',
    'invalidPassword': 'A valid password is required',
    'invalidFullName': 'A valid full name is required',
    'invalidFirstName': 'A valid first name is required',
    'invalidPhone': 'A valid phone is required',
    'invalidPosition': 'A valid company position is required',
    'invalidCompanyName': 'A valid company name is required',
    'emailIsNotValid': 'Email is not valid',
  },
  'attendance': {
    'title': 'Attendance',
    'avatar': 'Avatar',
    'name': 'Name',
    'position': 'Position',
    'status': 'Status',
    'addAttendees':'Add attendees',
    'absencesNotice': 'Absences',
    'noAttendance': 'No attendance',
    'search': 'Search',
    'attendedAttendance': 'Attended attendance',
    'joinMeeting': 'Join the meeting room',
    'addToMeeting': 'Add to meeting',
    'attendanceList': 'Attendance List',
    'emptyListMessage': 'Empty List',
    'group':{
      'addGroup': 'Add group',
      'title': 'Group',
      'groupDetail': 'Group Detail',
      'groupAddNew': 'Add new group',
      'groupID': 'Group ID',
      'groupName': 'Group Name',
      'description': 'Description',
      'numberOfPeople': 'Number of people',
      'userInGroup': 'User In Group List',
      'notificationDeleteGroup': 'Are You Sure You Want To Delete This Group',
      'selectUser': 'Select user',
      'userAlreadyExistsInTheGroup': "User already exists in the group",
      "notificationRemoveUser": "Are you sure you want to remove users from the group",
      'addNewUser': 'Add user'
    },
    'confirmUserAttendance': 'You agree to confirm attendance at the meeting?',
    'numberUserJoinMeeting': 'In the meeting room',
    'privilegeName': {
      'attendee': 'Attendee'
    }
  },
  'schedule': {
    'title': 'Schedule',
    'generalCalendar': 'General calendar',
    'favoriteCalendar': 'Favorite calendar'
  },
  'calendar': {
    'calendarDay': 'Calendar day',
    'calendarMonth': 'Calendar month',
    'calendarWeek': 'Calendar week',
    'calendarYear': 'Calendar year',
    'meetingCalendar': 'Meeting calendar',
    "noMeeting": "No events have taken place",
    "startDate": 'Start date'
  },
  'meeting': {
    'title': 'Meeting',
    'noData': 'No meeting information',
    'noDataFavorite': 'No favorite meeting information',
    'confirmChooseMeeting': 'Are you sure you want to choose the meeting',
    'chaired': 'Chaired',
    'attendanceList': 'Attendance list',
    'attendant': 'Attendant',
    'absence': 'Absence',
    'noAttendance': 'No attendance',
    'confirmRegisterMeeting': 'Do you want to register for the meeting?',
    'meetingEnd': 'The meeting has ended'
  },
  'scheduleMeeting': {
    'content': 'Meeting Content',
    'noDocument': 'No documentation',
    'documentTitle': 'Document'
  },
  'myCard': {
    'title': 'QR Code',
    'myQrCode': 'My QR Code',
    'myCard': 'My name card',
    'changeInfo': 'Change Information',
    'functionChangNameCard': 'Electronic name card exchange function',
    'functionChangNameCardContent': 'This code contains your own name-card information, so your partner scans this code, the information will be exchanged with each other'
  },
  'information': {
    'title': 'General meeting information',
    'meetingContent': 'Meeting content',
    'reasonAbsence': 'Absence',
    'notInvitationInfo': 'No invitation information'
  },
  'document': {
    'title': 'Documents',
    'generalDocuments': 'General document',
    'personal': 'Personal',
    'exchangeDocument': 'Exchange document',
    'public': 'Public',
    'private': 'Private',
    'note': {
      'title': 'Document noted',
      'personalNotes': 'Personal notes',
      'invalidEnterNote': 'Please enter a note',
      'enterNote': 'Enter note'
    },
    'security': 'Security',
    'time': 'Time',
    'approver': 'Approver',
    'notApprover': 'Not approver',
    'downloadDocument': 'Download document',
    'reportError': 'Report error',
    'noDocumentForThisContent': 'There are no documents for this content yet',
    'addContent': 'Add content',
    'enterNewContent': 'Enter new content',
    'startTime': 'Start time',
    'endTime': 'End time',
    'addDocument': 'Add document',
    'content': 'Content',
    'chooseContent': 'Choose content',
    'uploadNewDocument': 'Upload new document',
    'documentTitle': 'Document title',
    'enterTitle': 'Enter title',
    'needApproval': 'Need approval',
    'yes': 'Yes',
    'no': 'No',
    'assignApprover': 'Assign approver',
    'searchForApprover': 'Search for approvers',
    'messageForApprover': 'Message for approver',
    'enterMessage': 'Enter message',
    'searchForContent': 'Search for contents',
    'cannotExceed10MB': 'File size cannot exceed 10MB',
    'loading': 'Loading...',
    'role': {
      'title': 'Role',
      'reviewer': 'Review',
      'approver': 'Approve'
    },
    'documentNotApproved': 'Document not approved',
    'messageForDocumentCreator': 'Message for document creator',
    'reasonNotApproved': 'Reason not approved',
    'reject': 'Reject',
    'uploader': 'Uploader',
    'message': 'Message',
    'fieldRequire': {
      'contentAgenda': 'A content is required',
      'documentTitle': 'A document title is required',
      'documentMessage': 'A message is required',
      'chooseDocument': 'Please select a document',
      'chooseApprover': 'Please select approver'
    },
    'documentUploader': 'Document uploader',
    'updateDocumentUploader' : 'Update document uploader',
    'currentDocumentUploader': 'Current document uploader',
    'status': {
      'awaitingApproval': 'Awaiting approval',
      'complete': 'Complete',
      'approved': 'Approved',
      'rejected': 'Rejected'
    },
    'date': 'Date',
    'signTheNumber': 'Digital signature',
    'assignDocumentViewer': "Add document viewer",
    'all': 'All',
    'updateDocumentReviewer': 'Update document viewer',
    'currentDocumentViewer': 'Current document viewer',
    'titleConfirmDelete': 'Are you sure you want to delete document',
    'plsUploadPdfFile': 'Only support PDF file for document',
    'updateDigitalSigner': 'Update digital signer',
    'currentDigitalSigner': 'Current digital signer',
    'addNewDigitalSigner': 'Add new digital signer',
    'btnPersonalToGeneral': 'Switch to general documents',
    'chooseSession': 'Choose session',
    'documentNote': 'Documents note',
    'documentUpload': 'Documents upload',
    'documentComment': 'Document comments',
    'transferDocument': 'Transfer document',
    'deleteDocumentPersonalTitle': 'Are you sure you want to delete document upload',
    'documentRecipient': 'Document recipient',
    'selectDocumentRecipient': 'Select document recipients',
    'approvalDocumentExchange': 'Are you sure you want to approve the document',
    'documentName': 'Document name',
    'sender': 'Sender',
    'documentExchange': 'Document exchange',
    'reasonRejectDocumentExchange': 'Reasons for not approving',
    'exchangedDocument': 'Exchange documents',
    'shareDocument': 'Share document',
    'confirmDeleteEventSessionContent': 'Are you sure you want to delete content {0}'
  },
  'opinion': {
    'title': 'Opinion',
    'content': 'Opinion Content',
    'security': 'Security',
    'startTime': 'Start Time',
    'endTime': 'End Time',
    'followers': 'Followers',
    'opinionCollector': 'Opinion Collector',
    'plan': 'Plan',
    'submitComments': 'Submit Comments',
    'statistical': 'Opinion Statistical',
    'another_opinion': 'Another opinion',
    'haveSecurity': 'Yes',
    'noSecurity': 'No',
    'viewFull': 'View Full',
    'opinionCollectors': 'List of opinion collectors',
    'notificationAnswer': 'Your comments have been recorded',
    'yes/no': 'Yes/No',
    'addPlan': 'Add plan',
    'createOpinion': 'Create Opinion',
    'addOpinionContent': 'Input opinion content',
    'createNewOpinion': 'Create new opinion',
    'opinionContent': 'Content',
    'venueNotFound': 'Not found venue.',
    'errorNotAnswer': 'Input answer',
    'chartEmptyData': [{
      'labels': 'Empty'
    }],
    'anotherOpinionContent': 'The content of other opinions is:'
  },
  'sideBar': {
    'attendance': 'Attendance',
    'information': 'Information',
    'schedule': 'Schedule',
    'document': 'Document',
    'discuss': 'Discuss',
    'personalOpinion': 'Personal opinion',
    'notification': 'Notification',
    'myCode': 'My code',
    'contactList': 'Attendance list',
    'callSupport': 'Call support',
    'chooseMeeting': 'Leave meeting',
    'mission': 'Mission',
    'summary': 'Summary',
    'stated': 'Raise hand',
    'attendee': 'Seats Map',
    'feedback': 'Feedback',
    'goToAdminPage': 'Admin page',
    'history': 'History'
  },
  'userProfile': {
    'title': 'User profile',
    'email': 'Email',
    'active': 'Activated',
    'notActive': 'Not activated',
    'changePassword': 'Change password',
    'enterNewPassword': 'Enter new password',
    'fullName': 'Full name',
    'lastName': 'Last name',
    'firstName': 'First name',
    'phone': 'Phone',
    'companyName': 'Company name',
    'companyPosition': 'Company position',
    'signature': 'Signature',
    'numSignature': 'Digital signature form',
    'uploadSignatureFile': 'Download the signature file',
    'methodNumSignature': 'Select the digital signature form',
    'allowSms': 'Allow SMS',
    'allowEmail': 'Allow Email',
    'update': 'Update',
    'singOut': 'Logout',
    'sendConfirmMail': 'Send confirm mail',
    'sendConfirmSms': 'Send confirm sms',
    'fileMustBeImage': 'The file must be image'
  },
  'reasonAbsence': {
    'title': 'Reason Absence',
    'sendReasonAbsence': 'Send'
  },
  'mission': {
    'title': "Mission",
    'assigner': 'Assigner',
    'jobStatus': 'Status',
    'startTime': 'Start Time',
    'time': 'Time',
    'document': 'Document',
    'pause': 'Pause',
    'done': 'Done',
    'attach': 'Attach Document',
    'addNewMission': 'Create mission',
    'assignPersonCompany': 'Assign person/company',
    'missionTitle': 'Mission title',
    'discussInfo': 'Discuss info',
    'endTime': 'End time',
    'selectDocument': 'Select document',
    'uploadDocument': 'Upload new document',
    'sendJobAssign': 'Send job assignments',
    'conclusionForMission': 'Summary',
    'selectPerson': 'Select person/company',
    'summary': 'Summary',
    'receiver': 'Receiver',
    'received': 'Received',
    'reopen': 'Open',
    'notificationChangeStatus': 'Do you want to turn the task into a state'
  },
  'summary': {
    'title': 'Summary',
    'annunciator': 'Annunciator',
    'status': 'Status',
    'reportTime': 'Report time',
    'assignTask': 'Assigned tasks',
    'yes': 'Yes',
    'no': 'No',
    'done': 'Done',
    'public': 'Public',
    'waitingUpdate': 'Waiting update',
    'downDocument': 'Download document',
    'sendEmail': 'Send email',
    'addMission': 'Add mission',
    'addNew': 'Create Summary',
    'conclusionContent': 'Conclusion content',
    'conclusionStatus': 'Status',
    'conclusionAssigner': 'Assign person',
    'conclusionEndTime': 'Time',
    'conclusionSelectDocument': 'Select document',
    'uploadNewDocument': 'Upload new document',
    'saveConclusion': 'Save conclusion',
    'cancelConclusion': 'Cancel',
    'document': 'Document',
    'editSummary': 'Edit summary',
    'deleteSummary': 'Delete summary',
    'viewDocument': 'View Document',
    'confirmDeleteSummary': 'Are you sure you want to delete this summary',
    'returnPreviousPage': 'Return previous page',
    'otherDocument': 'Other document',
    'exportConclusions': 'Export conclusions',
    'confirmSendEmailForTask': 'Are you sure you want to send email to the people assigned to the task',
    'confirm': 'Confirm',
    'close': 'Close',
    'addDocument': 'Add new document',
    'uploadDocument': 'Upload document',
    'summaryInfo': 'Summary information'
  },
  'compareDate': {
    'startNotBiggerEndTime': 'Start time cannot be greater than end time',
    'startNotSmallerCurrentTime': 'Start time cannot be less than current time'
  },
  'stated': {
    'title': 'Please stated',
    'enterContentRegisterSpeak': 'Please enter content register speak',
    'enterContentSpeak': 'Enter content speak',
    'contentSpeak': 'Content speak',
    'editSpeak': 'Edit speak',
    'cancelRegister': 'Cancel register',
    'sendRegister': 'Send register',
    'status': 'Status',
    'timeSpeak': 'Speaking time',
    'noSpeak': 'Speech order',
    'speaker': 'Speaker',
    'speakStatus': {
      'speaked': 'Stated',
      'unspeak': 'Not speaking yet'
    },
    'requireContentSpeak': 'Opinion is required',
    'statusType': {
      'done': 'Done',
      'waiting': 'Waiting',
      'closed': 'Closed',
      'aborted': 'Aborted',
      'cancelled': 'Cancelled',
      'doing': 'Doing',
      'criticize': 'Criticize'
    },
    'speechPermission': 'Speech permission',
    'speechNotAllowed': 'Speech is not allowed',
    'close': 'Close',
    'criticize': 'Criticize',
    'update': 'Update',
    'cancel': 'Cancel',
    'hours': 'Hours',
    'minutes': 'Minutes',
    'hour': 'Hour',
    'minute': 'Minute',
    'seconds': 'Seconds',
    'seat': 'Seat',
    'addUserStated': 'Appoint a speaker',
    'enterContentCriticism': 'Please input criticism content',
    'inputContentCriticism': 'Input criticism content',
    'criticismTitle': 'criticism opinion ',
    'btnCriticism': 'Register criticism',
    'btnCancelCriticism': 'Cancel register criticism',
    'notFoundUserStated': 'There were no speakers'
  },
  'event': {
    'ongoingEvents': 'These events are happening on'
  },
  'support': {
    'title': 'Call support',
    'guideCalendarSupport': 'Call support',
    'contentSupport': 'Content needs support',
    'enterContentSupport': 'Enter the content to be supported',
    'chooseMeeting': 'Choose a meeting room',
    'sendRequest': 'Send request',
    'location': 'Location',
    'time': 'Time',
    'status': 'Status',
    'received': 'Received',
    'editContent': 'Edit content',
    'completed': 'Accomplished',
    'processing': 'Processing',
    'supportContentChat': 'Input support content',
    'thanksMessages': 'Thanks!',
    'selectMeeting': 'Select meeting',
    'addNewSupport': 'Add new support',
    'selectGroup': 'Select group',
    'closeRequest': 'Close the request'
  },
  'attendeeList': {
    'addUserInSeats': 'Add user in seats',
    'addUserToPosition': 'Add user to position',
    'chairMain': 'Chair Main',
    'userExistsTable': 'The user already exists in the table meeting!',
    'numberSeats': 'Number seat invalid',
    'position': 'POSITION',
    'delegates': 'DELEGATES',
    'delegate': 'DELEGATE',
    'no': 'NO',
    'errorRoomInfo': 'Not found room meeting info'
  },
  'notification':{
    'document':{
      'needApproval': 'Notification: has document {0} for meeting {1} need approval.',
      'approval': 'Notification: document {0} has approval by {1}',
      'reject': 'Notification: document {0} has reject by {1}'
    },
    'mission':{
      'changeStatus': 'Notification: mission {0} change {1} by {2}',
      'createMission': 'Notification: you have just been assigned tasks {0} in {1}, should finish before {2}',
      'missionStatus': {
        'done': 'done',
        'pause': 'pause',
        'reopen': 'reopen'
      }
    },
    'attendance':{
      'attendanceNotification': 'Notification: confirm you {0} have taken attendance',
      'reasonNotification': 'Notification: confirm you {0} have reported the absence with reason {1}'
    },
    'meetingRoom':{
      'confirmSeats': 'Notification: confirm your seats {0} in meeting {1} at {2}'
    },
    'summary':{
      'reportMeeting': 'Notification: meeting report {0} have just updated, please access to system to check again and confirm'
    },
    'title': 'Notification',
    'thingToDo': 'Thing to do',
    'allNotification': 'All notification',
    'goToDocs': 'go To Docs',
    'doNotHaveNewNotification': 'You do not have new notifications',
    'notificationTitle': 'Title',
    'notificationSubTitle': 'Subject',
    'notificationContent': 'Content',
    'deleteNotificationContent': 'Are you sure you want to delete this notification',
    'btnAction': {
      'attendance': 'Go to attendance',
      'confirm_seats': 'Location map',
      'document': 'Go to document',
      'mission': 'Go to mission',
      'opinion': 'Go to opinion',
      'stated': 'Go to stated'
    }
  },
  'search': {
    'title': 'Search for meeting',
    'period': 'Period',
    'searchTitle': 'Enter the title',
    'buttonSearch': 'Search',
    'emptyList': 'No results were found'
  },
  "paginator": {
    "showing": "Showing",
    "of": "Of",
    "records": "Records"
  },
  'feedback': {
    'feedbackContent': 'Feedback content'
  },
  'historyLog': {
    'descriptionText': {
      'loginMeeting': 'Login Paperless Conference',
      'logoutMeeting': 'Logout Paperless Conference',
      'joinMeeting': 'Join meetings - {{event_name}}',
      'leaveMeeting': 'Leave meetings - {{event_name}}'
    },
    'userAction': 'User action',
    'action': 'Action',
    'description': 'Description',
    'actionList': {
      'loginMeeting': 'Login',
      'logoutMeeting': 'Logout',
      'joinMeeting': 'Join meetings',
      'leaveMeeting': 'Leave meetings '
    },
    'dateCreated': 'Time'
  }
}