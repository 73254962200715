import moment from 'moment'
import dateUtils from './dateUtils'
import axios from 'axios'

/**
 * Check null
 * @param {*} data
 */
const isNull = function (data) {
  if (data === undefined || data === null) {
    return true
  } else {
    return false
  }
}
/**
 * Check permission
 * @param {*} loggin_info
 * @param {*} perm
 */
const hasPerm = function (logginInfo, perm) {
  let perms = logginInfo.permissions
  for (let i = 0; i < perms.length; i++) {
    let currentPerm = perms[i]
    if (currentPerm === perm) {
      return true
    }
  }
  return false
}

/**
 * Check has support role
 * @param {*} currentAccount 
 */
const isSupportRole = function (currentUser) {
  let role = currentUser ? currentUser.role : null
  if (role && role.id == 5) {
    return true;
  }
  return false;
}

/**
 * Check has secretary role
 * @param {*} currentUser 
 */
const isSecretaryRole = function (currentUser) {
  let role = currentUser ? currentUser.role : null
  if (role && role.id == 6) {
    return true;
  }
  return false;
}

/**
 * Check has chairman role
 * @param {*} currentUser 
 */
const isChairmanRole = function (currentUser) {
  let role = currentUser ? currentUser.role : null
  if (role && role.id == 7) {
    return true;
  }
  return false;
}

/**
 * Check is super user
 * @param {*} currentUser
 */
const isSuperUser = function (currentUser) {
  let userId = currentUser ? currentUser.id : null
  if (userId === 1) {
    return true
  } else {
    return false
  }
}
/**
 * Splice string
 * @param {*} strText 
 * @param {*} len 
 */
const spliceString = function (strText, len) {
  const truncate = (str, len) => str.substring(0, (str + ' ').lastIndexOf(' ', len));
  if (strText.length > len) {
    return truncate(strText, len) + '...';
  }
  else return strText;
}

const getCompanyAndPosition = function(companyName, companyPosition){
  if (companyName === null || companyName === "" && companyPosition === null || companyPosition === ""){
    return null
  }
  else if (companyName === null || companyName === ""){
    return companyPosition
  }else if (companyPosition === null || companyPosition === ""){
    return companyName
  }else{
    return companyName + ' - ' + companyPosition
  }
}

/**
 * Check empty string
 * @param {*} value 
 */
const isEmptyString = function (value) {
  return typeof value === 'string' && value.trim().length === 0;
}

/**
 * Convert b64data to blob
 * @param {*} b64Data 
 * @param {*} contentType 
 */
const b64toBlob = function (b64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 512;
  var byteCharacters = window.atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

/**
 * Change alias
 * @param {*} str 
 */
const changeAlias = function (str) {
  if (!isNull(str) && !isEmptyString(str)) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/đ/g, "d").replace(/Đ/g, "D");
  }
  return ''
}

/**
 * Remove special character
 * @param {*} str 
 */
const removeSpecialCharacter = function (str) {
  return str.replace(/[^a-zA-Z ]/g, "")
}

/**
 * Get file extension
 */
const getFileExtensions = function (str) {
  return str.substring(str.lastIndexOf('.') + 1, str.length) || str
}

/**
 * Get code alpha for row
 * @param {*} array 
 */
const getCodeAlphaForRow = function (array) {
  let alphas = Array.apply(undefined, Array(26)).map(function(x,y) { return String.fromCharCode(y + 65); });
  var array1 = []
  var array2 = []
  for (let i = 0; i < array.length; i++) {
      const element = array[i];
      element['code_alpha'] = alphas[i]
      if (i < array.length / 2) {
          array1.push(element)
      } else {
          array2.push(element)
      }
  }
  return [array1, array2]
}

/**
 * Romanize
 * @param {1} num 
 */
function romanize (num) {
  if (isNaN(num))
      return NaN;
  var digits = String(+num).split(""),
      key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
             "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
             "","I","II","III","IV","V","VI","VII","VIII","IX"],
      roman = "",
      i = 3;
  while (i--)
      roman = (key[+digits.pop() + (i * 10)] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
}

function uniDecodeString(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
}

const START_DATE_TIME = '00:00'
const END_DATE_TIME = '23:59'
// startStartTime, eventEndTime is format 'YYYY/MM/DD HH:mm'
function formatEventDateTime(startStartTime, eventEndTime){

  let startTime = moment(startStartTime, dateUtils.STATIC_FORMAT_DATE_TIME_SQL).format('HH:mm')
  let endTime = moment(eventEndTime, dateUtils.STATIC_FORMAT_DATE_TIME_SQL).format('HH:mm')

  if (startTime == START_DATE_TIME || startTime == END_DATE_TIME){
    startStartTime = dateUtils.formatDate(
      startStartTime,
      dateUtils.STATIC_FORMAT_DATE_TIME_SQL,
      dateUtils.STATIC_FORMAT_DATE_SQL
    )
  }
  if (endTime == START_DATE_TIME || endTime == END_DATE_TIME){
    eventEndTime = dateUtils.formatDate(
      eventEndTime,
      dateUtils.STATIC_FORMAT_DATE_TIME_SQL,
      dateUtils.STATIC_FORMAT_DATE_SQL
    )
  }

  //one day
  let startTimeDay = moment(startStartTime).format(dateUtils.STATIC_FORMAT_DATE_SQL)
  let endTimeDay = moment(eventEndTime).format(dateUtils.STATIC_FORMAT_DATE_SQL)
  // year
  let startTimeYear = moment(startStartTime).format('YYYY')
  let endTimeYear = moment(eventEndTime).format('YYYY')

  if(startTimeDay == endTimeDay){
    if (checkDateTimeTypeFormat(startStartTime, dateUtils.STATIC_FORMAT_DATE_SQL) && checkDateTimeTypeFormat(eventEndTime, dateUtils.STATIC_FORMAT_DATE_SQL)){
      return moment(startStartTime, dateUtils.STATIC_FORMAT_DATE_SQL).format(dateUtils.STATIC_FORMAT_DATE) 
    }else{
      return moment(startStartTime, dateUtils.STATIC_FORMAT_DATE_TIME_SQL).format('HH:mm') 
        + ' - ' + moment(eventEndTime, dateUtils.STATIC_FORMAT_DATE_TIME_SQL).format('HH:mm') + ' ' + 
        moment(startStartTime).format('DD/MM/YYYY')
    }
  }
  // year
  else if(startTimeYear == endTimeYear){
    if (checkDateTimeTypeFormat(startStartTime, dateUtils.STATIC_FORMAT_DATE_SQL) && checkDateTimeTypeFormat(eventEndTime, dateUtils.STATIC_FORMAT_DATE_SQL)){
      return moment(startStartTime, dateUtils.STATIC_FORMAT_DATE_SQL).format('DD/MM') 
        + ' - ' + moment(eventEndTime, dateUtils.STATIC_FORMAT_DATE_SQL).format('DD/MM/YYYY')
    }else{
      return moment(startStartTime, dateUtils.STATIC_FORMAT_DATE_TIME_SQL).format('HH:mm DD/MM') 
        + ' - ' + moment(eventEndTime, dateUtils.STATIC_FORMAT_DATE_TIME_SQL).format('HH:mm DD/MM/YYYY')
    }
  }
  else{
    if (checkDateTimeTypeFormat(startStartTime, dateUtils.STATIC_FORMAT_DATE_SQL) && checkDateTimeTypeFormat(eventEndTime, dateUtils.STATIC_FORMAT_DATE_SQL)){
      return moment(startStartTime, dateUtils.STATIC_FORMAT_DATE_SQL).format('DD/MM/YYYY') 
        + ' - ' + moment(eventEndTime, dateUtils.STATIC_FORMAT_DATE_SQL).format('DD/MM/YYYY')
    }else{
      return moment(startStartTime, dateUtils.STATIC_FORMAT_DATE_TIME_SQL).format('HH:mm DD/MM/YYYY') 
        + ' - ' + moment(eventEndTime, dateUtils.STATIC_FORMAT_DATE_TIME_SQL).format('HH:mm DD/MM/YYYY')
    }
  }
}

const validateStringIsNull=function(value){
  if(value==null){
    return true
  }else{
    return isEmptyString(value)
  }
}

function checkDateTimeTypeFormat(date, dateFormatCode){
  if (moment(date, dateFormatCode, true).isValid()){
    return true
  }
  return false
}

const validateEmail = function(email) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!pattern.test(email)) {
    email = "";
    return false;
  } else {
    return true;
  }
}
const validatePhone = function (phone) {
  const regex = /^(0[3|5|7|8|9][0-9]{8}|[+]84[3|5|7|8|9][0-9]{8})$/
  return !!regex.test(phone)
}

const getFirstLastName = function (fullName) {
  // Split full name to get last name and first name
  let fullNameArray = fullName.split(' ')
  let firstName = fullNameArray[fullNameArray.length - 1]
  let lastName = ''
  for (let i = 0, size = fullNameArray.length - 1; i < size; i++) {
    lastName += fullNameArray[i] + (i < size - 1 ? ' ' : '')
  }
  return {
    'firstName': firstName,
    'lastName': lastName
  }
}

const removeDoubleSlashesFromUrl = function (url) {
  return String(url).replace(/([^:]\/)\/+/g, "$1");
}

export const uploadFileToMediaAgent = async function (fileUpload, subDirectory, nameAlias = null) {
  let retImageURL = null
  let formData = new FormData()
  formData.append('secretKey', process.env.VUE_APP_MEDIA_AGENT_KEY)
  if (nameAlias == null) {
    formData.append('uploadfile', fileUpload)
  } else {
    formData.append('uploadfile', fileUpload, nameAlias)
  }
  formData.append('directory', subDirectory)
  formData.append('forceOverride', true)

  await axios({
    method: 'POST',
    timeout: 6000,
    url: process.env.VUE_APP_AGENT_MEDIA_URL + '/media-api/mediums/upload/',
    // headers: { 'Content-Type': 'application/json' },
    data: formData
  }).then(
    async function (res) {
      let data = res.data
      retImageURL = data.domain + data.url
    }
  ).catch(
    async function () {
      retImageURL = null
    }
  )
  return retImageURL
}

export default {
  isNull,
  hasPerm,
  isSuperUser,
  isSupportRole,
  isSecretaryRole,
  isChairmanRole,
  spliceString,
  getCompanyAndPosition,
  isEmptyString,
  b64toBlob,
  changeAlias,
  removeSpecialCharacter,
  getFileExtensions,
  getCodeAlphaForRow,
  romanize,
  uniDecodeString,
  formatEventDateTime,
  checkDateTimeTypeFormat,
  validateStringIsNull,
  validateEmail,
  validatePhone,
  getFirstLastName,
  removeDoubleSlashesFromUrl
}
